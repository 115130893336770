<template>
  <div class="xieyi">
    <h2>用户隐私协议</h2>
    <p>欢迎您使用粮策（以下称“我们”）的产品和服务！我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。基于此，粮策制订《隐私政策》（以下简称“本政策”），帮助您充分了解在您使用我们的产品或服务过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。 在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策末的联系方式与我们联系。 本隐私条款包含如下内容：</p>
    <h3>一、适用范围</h3>
    <h3>二、信息收集及使用</h3>
    <h3>三、对外提供信息</h3>
    <h3>四、您的权力</h3>
    <h3>五、信息的存储</h3>
    <h3>六、未成年人保护</h3>
    <h3>七、政策的更新</h3>
    <h3>八、法律适用、管辖与其他</h3>
    <h3>九、如何联系我们</h3>
    <h3>一、适用范围</h3>
    <p>
      本政策适用与粮策及其关联方以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们及关联公司提供的某款产品或服务有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品或服务的隐私将优先适用，该款产品或服务的隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。如我们关联公司的产品或服务中使用了我们提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。
      本政策所称的粮策王及其关联方是指有 限公司。
      需要特别说明的是，本隐私政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务（“其他第三方”，包括您的交易对象、任何第三方网站以及第三方服务提供者等），具体规定请参照该第三方的隐私政策或类似声明。例如：粮策平台上的卖家依托平台向您提供服务时，您向卖家提供的个人信息不适用与本隐私政策。
    </p>

    <h3>二、信息收集及适用</h3>

    <p>在您适用我们产品/服务时，我们需要/可能需要收集和适用的您的个人信息包括如下两种：</p>
    <p>1、为实现向您提供我们产品或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品或服务；</p>
    <p>2、为实现向您提供我们产品或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，而不同用户选择使用的具体产品/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准。</p>
    <p>3、为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作方的SDK或其他类似的应用程序。我们会对授权合作方获取有关信息的应用程序接口(API)、SDK进行严格安全检测，并与授权合作方约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作方单独取得您同意的除外。</p>
    <h4>第三方SDK收集信息规则如下</h4>
    <h5>（1）Flutter 基础功能-UI SDK</h5>
    <p>收集个人信息类型：获取粘贴板信息</p>
    <p>收集个人信息目的：用户用户在发布出售、采购、视频、编辑个人信息时方便用户粘贴复制</p>
    <h5>（2）友盟移动统计、推送SDK</h5>
    <p>收集个人信息类型：包名、版本、设备信息、设备厂商、设备序列号、ANDROID_ID</p>
    <p>收集个人信息目的：给用户推送离线通知、及时推送出售、采购、关注等用户信息</p>
    <h5>（3）华为、华为推送SDK</h5>
    <p>收集个人信息类型：包名、版本、设备信息、设备厂商、设备序列号、ANDROID_ID、加速度传感器</p>
    <p>收集个人信息目的：1、给用户推送离线通知、及时推送出售、采购、关注等用户信息 2、加速度传感器和GPS功能可以相互配合，从而实现手机的定位功能。通过记录手机的运动轨迹，可以精准的定位用户的位置。这一功能在地图导航等场景中十分实用。</p>
    <h5>（4）OPPO推送SDK</h5>
    <p>收集个人信息类型：包名、版本、设备信息、设备厂商、设备序列号、ANDROID_ID</p>
    <p>收集个人信息目的：给用户推送离线通知、及时推送出售、采购、关注等用户信息</p>
    <h5>（5）小米推送SDK</h5>
    <p>收集个人信息类型：包名、版本、设备信息、设备厂商、设备序列号、ANDROID_ID</p>
    <p>收集个人信息目的：给用户推送离线通知、及时推送出售、采购、关注等用户信息</p>
    <h5>（6）VIVO推送SDK</h5>
    <p>收集个人信息类型：包名、版本、设备信息、设备厂商、设备序列号、ANDROID_ID</p>
    <p>收集个人信息目的：给用户推送离线通知、及时推送出售、采购、关注等用户信息</p>
    <h5>（7）阿里云视频点播SDK</h5>
    <p>使用目的：使用阿里云视频点播播放器播放点播视频</p>
    <h5>（8）阿里云一键登录SDK(com.mobile,auth)</h5>
    <p>收集个人信息类型：设备信息、设备厂商、手机号码</p>
    <p>使用目的：减少用户登录成本，提高用户体验,支持用户一键本机号码登录</p>
    <h5>（9）腾讯SDK</h5>
    <p>使用目的：1、开通VIP时，选择微信支付方式 2、分享视频、咨询等信息到微信好友、微信朋友圈</p>
    <h5>（10）阿里支付SDK</h5>
    <p>使用目的：开通VIP时，选择支付宝支付方式</p>
    <h5>（11）QQ互联SDK</h5>
    <p>使用目的：分享视频、咨询等信息到QQ好友</p>
    <h5>（12）高德定位SDK 地图位置服务</h5>
    <p>收集个人信息类型：包名、版本、设备信息</p>
    <p>收集个人信息目的：获取用户的当前地理位置，展示附近的商家和资源信息，和距离信息</p>
    <h5>（13）相机权限、相册权限</h5>
    <p>收集个人信息类型：获取系统摄像头相机权限</p>
    <p>收集个人信息目的：用于用户完善个人信息时上传头像、发布售卖、采购、视频信息时进行拍照或者录制视频</p>
    <h5>（14）glide图片加载库获取SD卡</h5>
    <p>收集个人信息类型：获取存储权限，获取外置存储信息(SD卡数据)</p>
    <p>收集个人信息目的：用于用户保存喜欢的图片、视频内容到本地相册，检查手机SD卡情况</p>
    <p>
      我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，而不同用户选择使用的具体产品/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准。
      我们会为您提供的各想具体功能场景包含：
    </p>

    <h4>（一）帮助您成为我们的用户及账户管理</h4>
    <h5>1、基础服务</h5>

    <p>我们基于粮策平台账户为您提供服务。为创建粮策平台账户您需要至少向我们提供您的手机号和验证密码/邀请码。如果您拒绝提供上述信息，您将无法注册粮策平台账户，仅可以使用浏览、搜素服务。</p>

    <h5>2、身份验证</h5>
    <p>为满足相关法律法规定及监管要求、确保用户身份真实性、实现反欺诈等风险控制、保障系统和服务安全，在您使用特定服务/功能时，我们需要收集您的相关信息进行身份验证，如： 实名/实人认证：基于实现特殊类型商品或服务交易、申请存在额外身份认证要求的用户角色（如卖家、内容创作者）以及其他依法需要核验用户真实身份的场景（包括依法波阿虎未成年人权益、打击电信网络诈骗、行政执法或司法投诉中相关主体认定、找回账号密码）的必要，您需要进行实名或实人认证。为实现认证目的，您需要主动提供相关真实身份信息（姓名、证件号码等证件信息或面部识别信息，根据实际情况可能有所不同，以页面提示为准）并授权我们通过国家权威可信身份认证机构进行信息核验，我们将依法记录、保存认证信息及认证结果，这些信息仅供完成认证目的，或其他法律法规定的用途，未经您明示授权不会用作其他目的。</p>
    <h4>（二）向您展示访问足迹、历史记录等</h4>
    <p>为您展示商品或服务信息，包括您的访问足迹、历史搜素等情况，我们会收集和使用您在访问或使用粮策APP或PC网站时的浏览、搜素记录。同时，我们通过一下措施努力保障您的隐私体验：</p>
    <p>1、如您不想接受我们给您发送的商业信息，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭；</p>
    <p>2、如您在使用我们提供的站内搜索服务时，你可以在搜索结果页面点击“筛选”进行设置。</p>
    <h4>（三）帮助您完成支付</h4>
    <p>
      为完成订单支付，当您需要使用第三方支付提供的快捷支付方式时，您需要向第三方支付机构或银行提供姓名、银行卡号、银行卡有效期、CVV2等在内的必要信息，该信息为完成银行卡快捷支付的必要条件，粮策不会将此信息用于其他用途或泄露给其他机构或个人。
      为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象或您选择的其他金融机构处收集与支付进度相关信息。
    </p>（四）为您提供信息公开发布功能
    你可通过我们为您提供的评论、问答、视频、个人主页及其他信息发布功能，选择公开发布图文/视频/直播内容，发表评价及问答内容，以及作为卖家发布商品或服务相关信息、店铺相关信息。我们尊重并保护您对相关信息是否发布以及发布范围的设置。
    我们可能会根据您的用户类型及网络日志信息，判断您是否可享受对应信息发布功能权限。
    请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至敏感个人信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及他个人信息的，您需在发布前征得他人同意。
    <h4>（五）为您提供安全保障</h4>
    <p>
      为履行保障电子商务交易安全的法定义务，提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入风险，更准确地识别违反法律法规或粮策平台相关协议、规则的情况，我们在应用程序中嵌入我们合作伙伴公司开发的应用安全SDK收集您的设备信息，服务日志信息以及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、设备标识符（包括：设备序列号、IMEI、AndroID、OAID、IMSI、ICCID、GAID、MEID，IOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、deviceld（需要说明的是该deviceld与设备唯一标志符无任何关联）、设备传感器信息、网络设备硬件地址（MAC地址）、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志等数据。
    </p>
    <h4>（六）自启用和关联启动说明</h4>
    <p>1、为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为,是因实现功能及服务所必要的。</p>
   <h4>（七）推荐和为您推荐的说明</h4>
   <p1>1、首页-现货详情-为您推荐功能、首页-资讯详情-推荐功能，不含定向推送和广告精准营销功能，均为全部推荐数据</p1>
    <h3>三、对外提供信息</h3>
    <h4>（一）共享</h4>
    <p>您的个人信息是我们为您提供服务的重要部分，我们会遵循法律法规对您的信息承担保密义务。除以下情形外，我们不会将您的信息披露给第三方：</p>
    <p>1、履行法定义务所必需的共享：我们可能会根据法律法规、诉讼、争议解决的必要或按行政、司法关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。</p>
    <p>2、取得您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
    <p>3、为订立、履行您作为一方当事人的合同所必需的情况下的共享：您通过粮策平台购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易、配送及售后服务需求。</p>
    <p>
      4、与关联公司间共享：为便于我们与关联公司共同向您提供部分服务，基于粮策平台账户向您提供产品和服务，识别账户异常，保护粮策平台关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
      请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。
    </p>
    <h5>（二）委托处理</h5>
    <p>
      我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其他处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。
      目前，我们委托的授权合作伙伴包括以下类型：
    </p>
    <p>1、广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。</p>
    <p>
      2、供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查，以及在您选择使用我们由合作伙伴提供技术和服务支持的产品服务（例如实地认证）时，将合作伙伴提供服务所必需的信息，例如您的姓名、电话、地址、企业信息等提供给服务提供商，以便其基于您的信息为您提供相应的服务。
      为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作伙伴单独取得您同意的除外。
    </p>
    <p>1、极光认证SDK:我们为向您提供智能认证服务，需要通过该信息识别用户应用环境，帮助你的网站与APP识别与拦截机器程序的批量自动化操作。</p>
    <p>2、QQ互联SDK:为实现QQ互联SDK (Android版) 分享到QQ/TIM、加好友等功能，判断用户手机上是否安装QO或TIM。 粮策APP涉及用户信息使用的SDK业务场景、所需用户信息、隐私政策链接逐项列举。</p>

    <h5>（三）转让</h5>
    <p>如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</p>
    <h4>（四）公开披露</h4>
    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
    <p>1、如果我们确定您出现违反中华人民共和国有关法律、法规规定或者违反您与粮策网签署的相关协议 (包括在线签署的电子协议) 或违反相关粮策平台规则的情况，或为保护粮策平台用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及粮策平台已对您采取的措施。</p>
    <p>2、基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息。</p>
    <h3>四、您的权利</h3>
    <p>我们为方便您管理您的信息提供了便捷的方法，您可以通过以下方式进行管理，我们会在符合法律法规要求的情形下响应您的请求:</p>
    <h4>（一）查询、更正和补充</h4>
    <p>您有权通过以下方式查询、更正和补充您的信息：</p>
    <p>1、您可以自行在您填写相关信息时或在您的会员服务中心(即粮策APP、M站、微信小程序、百度小程序“我的”界面，粮策PC站“买家中心”“卖家中心”)中，进行选择是否在粮策平台公示您的信息。您知悉，如您选择公示您的信息，您的信息将会被公开展示。</p>
    <p>2、您可以通过登录粮策APP，在“我的-编辑资料”界面中查询、更正、补充您自己的个人信息等。</p>
    <p>3、如您对查询、更正、补充您的个人信息有疑问时，您可以联系粮策客服进行咨询及解决。</p>
    <h4>（二）删除</h4>
    <p>在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求:</p>
    <p>1、如果我们处理个人信息的行为违反法律法规;</p>
    <p>2、如果我们收集、使思你的个人信自，却去征得你的明确同意;</p>
    <p>3、如果我们处理个人信息的行为严重违反了与您的约定;</p>
    <p>4、如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
    <p>5、如果我们停止提供产品或者服务，或者保存期限已届满。</p>
    <p>
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除(除非法律法规另有规定，或这些主体已独立获得您的授权)
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
    </p>
    <h4>（三）注销账户</h4>
    <p>1、您可以通过登录粮策APP，在“我的”“编辑资料”“账号注销”界面注销您的账户。需知悉，只有当您账户不存在风险、交易 (种子种苗商家申请注销前120天内无交易记录，其他类目申请注销前30天内无交易记录)、售后等相关事项时，才能注销账户。</p>
    <p>
      2、通过客服联系，申请注销您的账户。
      在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。
    </p>
    <h4>（四）约束信息系统自动决策</h4>
    <p>在某些业务功能中，我们可能依据非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。</p>
    <h4>（五）响应您的上述请求</h4>
    <p>对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们，我们将在15天内做出答复。</p>
    <p>
      为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份 (如增加账户核验、要求您提供书面请求或其他合理方式)，然后再处理您的请求。
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段(如需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
    </p>
    <h3>五、信息的存储</h3>
    <h4>（一）存储地点</h4>
    <p>我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。</p>
    <h4>（二）存储期限</h4>
    <p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准:</p>
    <p>1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉;</p>
    <p>2、保证我们为您提供服务的安全和质量;</p>
    <p>3、您是否同意更长的留存期间;</p>
    <p>4、保证我们在可能的诉讼案件中向法院进行举证；</p>
    <p>5、是否存在关于保留期限的其他特别约定或法律法规规定；</p>
    <p>在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
    <h4>（二）存储安全</h4>
    <p>粮策严格保护您的信息安全，我们将提供如下方法管理保护您的信息:</p>
    <h5>1、数据保护管理组织措施</h5>
    <p>我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性。我们制定了《信息安全检查管理办法》《信息安全违章行为责任追究办法》《信息安全事件报告管理办法》等办法，来管理规范个人信息的存储和使用;参加各类平台线上及线下的安全和院私保护培训课程，不断加强员工对于保护个人信息重要性的认识。</p>
    <h5>2、数据保护技术措施</h5>
    <p>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受SSL协议加密保护，我们提供HTTPS协议安全浏览方式;我们会使用受信赖的保护机制防止个人信息遭到恶意攻击;我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。目前，我们的信息系统已经通过信息系统安全等级保护三级认证。</p>
    <h5>3、账户安全风险的防范</h5>
    <p>您在通过粮策与第三方进行网上商品或服务的交易、展示您的商品或企业信息时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式、邮件地址、联系人、公司详情等。这些信息为您自愿或同意后方可披露。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。如您发现自己的信息泄密，尤其是你的账户及(或)密码发生泄露，请您立即联络粮策平台的客服，以便我们采取相应措施。</p>
    <h5>4、个人信息安全事件的响应</h5>
    <p>如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
    <h3>六、未成年人保护</h3>
    <p>
      在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。如果您是14周岁以下儿童的，在使用粮策服务前，您应取得您父母或法定监护人的同意后，才可在监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。
      若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的服务。如您对儿童的个人信息有疑问，请与我们的客服联系。
    </p>
    <h3>七、政策的更新</h3>
    <p>
      为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会在粮策更新公告页面上发布对本政策所做的任何变更，并以首页弹窗的方式提醒您阅读相关内容的更新，也请您访问粮策以便及时了解最新的隐私政策。
      对于重大变更，我们还会提供更为显著的通知(包括我们会通过粮策平台公示的方式进行通知或向您提供弹窗提示)。
      本政策所指的重大变更包括但不限于：
    </p>
    <p>1、产品的业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式;</p>
    <p>2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式;</p>
    <p>3、个人信息共享或公开披露的主要对象发生变化;</p>
    <p>4、用户个人信息权利及其行使方式发生重大变化;</p>
    <p>5、处理个人信息保护的投诉渠道发生变化时;</p>
    <p>6、个人信息保护影响评估报告表明产品存在对个人权益有重大影响时。</p>
    <h3>八、法律适用、管辖与其他</h3>
    <p>1、本协议的内容、解释或其执行所产生的任何争议、权利主张或其它事项，均适用中华人民共和国大陆地区法律，并且排除一切冲突法规定的适用。</p>
    <p>2、您因使用我方平台服务所产生及与我方平台服务有关的争议，由我广与您协商解决。协商不成时，任何一方均可向被告住所地或合同履行地的人民法院提起诉讼。</p>
    <p>3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
    <h3>九、如何联系我们</h3>
    <p>如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议请通过以下方式与我们联系，我们将在15天内回复您的请求:</p>
    <p>公司名称：德州微派网络科技有限公司</p>
    <p>客服电话: 4001617896</p>
    <p>联系地址：山东省德州市武城县古贝春大街东首1602号</p>
    <p>在线客服: 登录粮策APP、小程序、PC站，点击在线客服。</p>
    <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
  </div>
</template>

<script>
export default {
  name: 'LcvueUserPrivacy',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.xieyi {
  text-align: left;
  padding: 40px;
}
</style>